<template>
  <div class="official-acc-auth">
    <pageTitle pageTitle="公众号授权">
      <div slot="content">
        <p>1、一个微信公众号只能和微吼账号绑定；</p>
        <p>2、公众号必须是已认证的服务号，才有微信消息提醒功能；</p>
        <p>3、认证服务号绑定之后，如需解绑请至微信公众号后台取消绑定；</p>
        <p>
          4、公众号授权之后，请确保已开通「订阅通知」，并将模板添加至「我的模板」：订阅直播即将开始提醒
        </p>
        <p>5、请默认授予所有权限，否则导致无法发送模板消息、无法准确判断是否关注等异常。</p>
      </div>
      <div class="auth-instructions" @click="showAuthInstructions">公众号授权说明</div>
    </pageTitle>
    <div class="authorized-container" v-if="[1, 4].includes(this.authData.status)">
      <div class="authorized-box">
        <div class="cancel-auth-tip" v-if="[4].includes(authData.status)">授权被取消</div>
        <div class="top">
          <div class="img-box">
            <img
              class="wechat-oa-img"
              v-if="authData.wechat_oa_head_img"
              :src="authData.wechat_oa_head_img"
              alt="托管公众号图标"
            />
          </div>
          <div class="wechat-oa-name">{{ authData.wechat_oa_name }}</div>
        </div>
        <div class="bottom" @click="showCancelAuthTips" v-preventReClick>删除</div>
      </div>
    </div>
    <div v-else>
      <null-page nullType="setting" text="暂未绑定公众号">
        <vh-button type="primary" round @click="showAuthTips" v-preventReClick>
          添加公众号
        </vh-button>
      </null-page>
    </div>

    <vh-dialog
      custom-class="dialog-auth-instructions"
      :visible.sync="authInstructionsVisible"
      width="700px"
      title="公众号授权说明"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-if="authInstructionsVisible"
    >
      <AuthInstructions></AuthInstructions>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          @click="authInstructionsVisible = false"
          round
        >
          我知道了
        </vh-button>
      </span>
    </vh-dialog>

    <VhallDialog
      title="提示"
      :visible.sync="authTipsVisible"
      :close-on-click-modal="false"
      custom-class="dialog-auth-tips"
      width="420px"
    >
      <div class="tips-box">
        请确保您授权账号为服务号且已经经过微信认证，否则可能导致自定义菜单、群发消息、粉丝池等功能无法使用。
        <br />
        <span class="auth-instructions" style="margin-left: 0" @click="showAuthInstructions">
          点击查看公众号授权说明>
        </span>
        <br />
        为确保您在微吼平台功能的正常使用，授权时请保持默认选择，把权限统一授权给微吼平台。
        <br />
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button type="info" @click="authTipsVisible = false" plain round>取 消</vh-button>
        <vh-button type="primary" v-preventReClick @click="authTipsConfirm" round>确 定</vh-button>
      </span>
    </VhallDialog>
    <VhallDialog
      v-if="authQrVisible"
      title="授权公众号"
      :visible.sync="authQrVisible"
      :close-on-click-modal="false"
      custom-class="dialog-auth-qr"
      width="420px"
    >
      <div class="qr-box">
        <img
          :src="authQr"
          v-if="authQr"
          v-loading="authQrLoading"
          class="qr-img"
          alt="授权公众号二维码"
        />
        <div v-else v-loading="authQrLoading" class="qr-img"></div>
        <div class="qr-tips">请使用公众号管理员个人微信号扫描</div>
      </div>
    </VhallDialog>

    <!-- 取消授权确认框  -->
    <VhallDialog
      title="提示"
      :visible.sync="cancelAuthTipsVisible"
      :close-on-click-modal="false"
      custom-class="dialog-auth-tips"
      width="420px"
    >
      <div class="tips-box">
        请确认已在微信公众平台内取消授权。
        <br />
        彻底解绑公众号首先需要在微信公众平台取消授权，然后在微吼中删除公众号。详细取消授权方式
        <span class="auth-instructions" @click="showAuthInstructions">
          点击查看公众号授权说明 &gt;
        </span>
      </div>
      <span slot="footer" class="dialog-footer create-footer">
        <vh-button
          type="primary"
          size="medium"
          v-preventReClick
          @click="cancelAuthTipsConfirm"
          round
        >
          访问公众平台
        </vh-button>
        <vh-button size="medium" @click="cancelAuthTipsVisible = false" plain round>
          取 消
        </vh-button>
      </span>
    </VhallDialog>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import NullPage from '../PlatformModule/Error/nullPage.vue';
  import AuthInstructions from '@/components/OfficialAcc/AuthInstructions';
  import { sessionOrLocal } from '@/utils/utils';
  const TASK_INTERVAL_TIME = 1000;
  import QRCode from 'qrcode';

  export default {
    name: 'OfficialAccAuth',
    components: {
      NullPage,
      PageTitle,
      AuthInstructions
    },
    data() {
      return {
        authInstructionsVisible: false,
        authTipsVisible: false,
        cancelAuthTipsVisible: false,
        authQrVisible: false,
        authData: {
          // wechat_oa_appid:'',//托管公众号appid
          // wechat_oa_name:'',//托管公众号名称
          // wechat_oa_head_image:'',//托管公众号图标
        },
        authStatusTask: null, //轮询直播任务
        authQr: '',
        userId: JSON.parse(sessionOrLocal.get('userId')),
        taskId: '',
        testImg: '',
        authQrLoading: false
      };
    },
    watch: {
      authQrVisible(newVal) {
        if (!newVal) {
          this.authQr = '';
          this.clearAuthStatusTask();
        }
      }
    },
    methods: {
      /**
       * 展示授权说明弹框
       */
      showAuthInstructions() {
        this.authInstructionsVisible = true;
      },
      /**
       * 展示授权提示弹框
       */
      showAuthTips() {
        this.authTipsVisible = true;
      },
      showCancelAuthTips() {
        this.cancelAuthTipsVisible = true;
      },
      /**
       * 授权提示确认回调
       */
      authTipsConfirm() {
        this.authQrVisible = true;
        this.authQrLoading = true;
        let opt = {
          business_uid: this.userId,
          usage: 2
        };
        this.$fetch('startOauthWechatThirdParty', opt, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            this.authQrLoading = false;

            this.authTipsVisible = false;
            let authQr = res?.data?.auth_url;
            if (authQr) {
              try {
                let opt = {
                  errorCorrectionLevel: 'H',
                  type: 'image/jpeg',
                  quality: 0.3,
                  margin: 1,
                  color: {
                    dark: '#000',
                    light: '#fff'
                  }
                };
                QRCode.toDataURL(authQr, opt)
                  .then(url => {
                    console.log(url);
                    this.authQr = url;
                  })
                  .catch(err => {
                    console.error(err);
                  });
              } catch (e) {
                console.error('this.authQr e', e);
              }

              console.error('this.authQr', this.authQr);
            }

            this.taskId = res?.data?.task_id;
            if (this.taskId) {
              this.authStatusTask = setInterval(() => {
                this.getAuthStatus();
              }, TASK_INTERVAL_TIME);
            }
          })
          .catch(res => {
            this.authQrLoading = false;
            this.authQrVisible = false; // 关闭弹框，清除轮询任务
            this.authQr = '';
            this.$vhMessage({
              message: res.msg || '获取授权二维码失败',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      cancelAuthTipsConfirm() {
        this.cancelAuthTipsVisible = false;
        // let opt = {};
        // this.$fetch('unbindWechatThirdParty', opt, {
        //   'Content-Type': 'application/json'
        // })
        //   .then(res => {
        //     this.$vhMessage({
        //       message: '删除成功',
        //       showClose: true,
        //       // duration: 0,
        //       type: 'success',
        //       customClass: 'zdy-info-box'
        //     });
        //     this.getAuthData();
        //   })
        //   .catch(res => {
        //     this.$vhMessage({
        //       message: res.msg || '删除失败，请重试',
        //       showClose: true,
        //       // duration: 0,
        //       type: 'error',
        //       customClass: 'zdy-info-box'
        //     });
        //   });
        window.open('https://mp.weixin.qq.com/');
      },
      getAuthData() {
        this.$fetch(
          'getBoundManagedWechatOa',
          {},
          {
            'Content-Type': 'application/json'
          }
        )
          .then(res => {
            this.authData = res?.data || {};
          })
          .catch(res => {
            // 根据错误码判断公众号是否已授权
            this.authData = {};
          });
      },
      getAuthStatus() {
        let opt = {
          task_id: this.taskId
        };
        if (!opt.task_id) {
          return false;
        }
        this.$fetch('authTaskStatusWechatThirdParty', opt, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            // 授权任务状态, 0: 未授权 1: 已授权, 2: 授权中 3: 从DEP主动解除授权 4:客户从公众号平台取消授权
            if (res?.data?.status === 1) {
              this.getAuthData();
              this.authQrVisible = false; // 关闭弹框，清除轮询任务
              this.$vhMessage({
                message: '授权成功',
                showClose: true,
                // duration: 0,
                type: 'success',
                customClass: 'zdy-info-box'
              });
            } else if (res?.data?.status === 2) {
              // 继续轮询
              return true;
            } else {
              // 0: 未授权 3: 从DEP主动解除授权 4:客户从公众号平台取消授权
              this.getAuthData();
              this.authQrVisible = false; // 关闭弹框，清除轮询任务
            }
          })
          .catch(res => {
            this.authQrVisible = false; // 关闭弹框，清除轮询任务
            this.$vhMessage({
              message: res.msg,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      },
      clearAuthStatusTask() {
        this.authStatusTask && clearInterval(this.authStatusTask);
        this.authStatusTask = null;
      }
    },
    created() {
      this.getAuthData();
    },
    beforeDestroy() {
      this.clearAuthStatusTask();
    }
  };
</script>

<style lang="less" scoped>
  .official-acc-auth {
    .auth-instructions {
      color: #3562fa;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 8px;
      cursor: pointer;
    }
    .authorized-container {
      width: 100%;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      padding: 48px 40px 40px 40px;
      overflow: hidden;
      min-height: 676px;
      .authorized-box {
        width: 308px;
        height: 182px;
        border-radius: 4px;
        background-color: #f5f5f5;
        position: relative;
        .cancel-auth-tip {
          border-radius: 4px 0px 12px 0px;
          background-color: #ffd1c9;
          position: absolute;
          top: 0;
          left: 0;
          color: #fb2626;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 0 8px;
        }
        .top {
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //flex-shrink: 0;
          height: 146px;
          .img-box {
            width: 64px;
            height: 64px;
            border-radius: 8px;
            margin: 0px auto 8px;
            display: flex;
            padding: 32px 0 0 0;
            box-sizing: content-box;
            .wechat-oa-img {
              width: 100%;
              height: 100%;
            }
          }
          .wechat-oa-name {
            margin-bottom: 25px;
            color: #262626;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            display: block;
            text-align: center;
          }
        }
        .bottom {
          display: flex;
          width: 308px;
          padding: 7px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 0px 4px 4px;
          background-color: rgba(0, 0, 0, 0.45);
          color: rgba(255, 255, 255, 0.85);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          box-sizing: border-box;
          height: 36px;
          cursor: pointer;
        }
      }
    }
  }

  .dialog-auth-tips {
    .tips-box {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      .auth-instructions {
        color: #3562fa;
        cursor: pointer;
      }
    }
  }

  .dialog-auth-qr {
    .qr-box {
      display: flex;
      flex-flow: column;
      .qr-img {
        width: 168px;
        height: 168px;
        margin: 0 auto 9px;
      }
      .qr-tips {
        color: #595959;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
      }
    }
  }
</style>
