import { render, staticRenderFns } from "./officialAccAuth.vue?vue&type=template&id=4715a69f&scoped=true&"
import script from "./officialAccAuth.vue?vue&type=script&lang=js&"
export * from "./officialAccAuth.vue?vue&type=script&lang=js&"
import style0 from "./officialAccAuth.vue?vue&type=style&index=0&id=4715a69f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4715a69f",
  null
  
)

export default component.exports