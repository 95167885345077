<template>
  <div class="official-acc-auth-tips">
    <!--    <p class="h2 is-first">1.概述</p>-->
    <p class="content">
      <a class="href" href="https://mp.weixin.qq.com/" target="_blank">微信公众平台，</a>
      是运营者通过公众号为微信用户提供资讯和服务的平台《微信官方介绍文档》，是以微信用户的一个联系人形式存在的，消息会话是公众号与用户交互的基础。
    </p>
    <p class="h2 is-first">1.对接公众号的作用：</p>
    <p class="content">（1）可以在自己的服务号推送直播消息，预热直播；</p>
    <p class="content">
      （2）新关注服务号的粉丝可以收到关注提醒，后续可以收到开播消息，增强用户粘性；
    </p>
    <p class="content">（3）预约、报名成功通知，确认用户操作行为并增强提醒；</p>
    <p class="content">（4）直播为公众号引流，利用传播效应为服务号吸粉；</p>
    <p class="h2">2.如何绑定微信公众号</p>
    <p class="content">
      在【运营设置】-【公众号授权】中点击【绑定微信公众号】，请注意若使用公众号通知，则必须绑定‘服务号’
    </p>
    <p class="content">
      <img
        class="img"
        src="../../common/images/official/authInstructions_page.png"
        alt="公众号授权"
      />
    </p>
    <p class="content">
      跳转到微信公众平台并显示授权二维码，如下图所示。此时需要公众号管理员扫描二维码进行授权。请注意必须是管理员，普通操作人员不可以进行授权。提醒：通过二维码截图在手机上长按识别无法进行授权，需要使用微信扫一扫。
    </p>
    <p class="content">
      <img
        class="img"
        src="../../common/images/official/authInstructions_qr.png"
        alt="公众号授权二维码"
      />
    </p>
    <div>
      <p class="content">为了更好地使用微吼来进行微信营销，请确保至少授权以下 8 个权限：</p>
      <ul class="content">
        <li>·服务号订阅通知</li>
        <li>·消息管理权限</li>
        <li>·自定义菜单权限</li>
        <li>·网页服务权限</li>
        <li>·群发与通知权限</li>
        <li>·用户管理权限</li>
        <li>·账号服务权限</li>
        <li>·素材管理权</li>
        <li>·限微信卡券权限</li>
      </ul>
      <p class="content">
        绑定成功后，在公众号设置页面会显示当前绑定的公众号，一个公众号可同时授权给 5
        家供应商，所以不必担心授权给微吼之后会不会影响到其它供应商。
      </p>

      <p class="content">
        如果状态出现‘授权被取消’标签，则说明当前公众号已经取消了对微吼的授权，您可以选择重新绑定或直接删除此公众号。
      </p>
      <p class="content">
        当您已将公众号授权给到微吼后，使用微信通知能力，还需要开通了「订阅通知」能力、添加指定模板。
        <a class="href" href="https://saas-doc.vhall.com/opendocs/show/1548" target="_blank">
          《开通微信订阅通知》
        </a>
      </p>
      <!--      <p class="h2">3.订阅号与服务号的区别</p>-->
      <!--      <p class="content mgb0">3.1 接口权限的区别</p>-->
      <!--      <p class="content">图片来源微信官方文档 <a class="href" href="https://developers.weixin.qq.com/doc/offiaccount/Getting_Started/Explanation_of_interface_privileges.html" target="_blank">《接口权限说明》</a></p>-->
      <!--      <p class="content">-->
      <!--        <img-->
      <!--          class="img"-->
      <!--          src="../../common/images/official/authInstructions_privileges.png"-->
      <!--          alt="公众号授权权限说明"-->
      <!--        />-->
      <!--      </p>-->
      <p class="h2">3.服务号授权后的基础应用</p>
      <p class="content mgb0">3.1 捕获访问网站的微信粉丝数据</p>
      <p class="content">
        在微吼中通过带身份识别的公众号二维码，捕获访问网站的微信粉丝数据，达成微信与网站的触点打通。一般网站能采集到的访客身份信息都是
        Cookie，
      </p>
      <p class="content">
        而 Cookie
        作为一串字符是没有任何意义的，通过在网站上放置带身份识别的公众号二维码，可以将网站访客身份与公众号粉丝对应起来，并记录该粉丝
        的所有网站访问行为。
      </p>
      <p class="content mgb0">3.2 自动给粉丝发送公众号回复消息</p>
      <p class="content">
        绑定认证服务号后，可以通过自动流程功能实现自动给关注的粉丝发送活动链接。
      </p>

      <p class="h2">4.如何解绑公众号</p>
      <p class="content">
        彻底解绑公众号首先需要在微信公众平台取消授权，然后在微吼中删除公众号。一个公众号只能绑定一个微吼账号，删除公众号后，才可在其他微吼账号租户
        中使用该公众号。
        在公众号后台，【公众号设置】-【授权管理】，在已授权微吼处点击【查看平台详情】进入页面后点击【取消授权】，即可取消微信对微吼
        的授权。
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AuthInstructions'
  };
</script>

<style lang="less" scoped>
  .official-acc-auth-tips {
    height: 60vh;
    overflow: auto;
    padding-right: 10px;
    .h2 {
      color: #262626;
      text-align: justify;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 24px;
      margin-top: 24px;
      &.is-first {
        margin-top: 0px;
      }
    }
    .content {
      color: #262626;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 12px;
      .href {
        color: #0a7ff5;
      }
      .img {
        width: 512px;
      }
      &.mgb0 {
        margin-bottom: 0px;
      }
    }
    .h3 {
      color: #262626;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }
</style>
